import _ from "lodash";
import { Graph } from "@antv/x6";
import { getScaleRate } from "@/utils/rem";
const scale = getScaleRate();
// 自定义连接桩
const personalizedPorts = {
  leftPort: {
    name: "leftPort",
    fn: (portsPositionArgs: any[]) => {
      const heightMargin = 35 * scale;
      return portsPositionArgs.map((_, index) => ({
        position: {
          x: -30 * scale,
          y: (index + 1) * heightMargin,
        },
        angle: 0,
      }));
    },
  },
  rightPort: {
    name: "rightPort",
    fn: (portsPositionArgs: any[], elemBBox: { width: number }) => {
      const heightMargin = 35 * scale;
      return portsPositionArgs.map((_, index) => ({
        position: {
          x: elemBBox.width + 30 * scale,
          y: (index + 1) * heightMargin,
        },
        angle: 0,
      }));
    },
  },
};
const hasRegisterPort: string[] = [];

export default () => {
  const initGraph = (
    container: HTMLElement,
    events: Record<string, any> = {},
    options: any = {}
  ) => {
    // 注册自定义连接桩
    Object.values(personalizedPorts).forEach((port) => {
      registerPortLayout(port);
    });
    const graph = new Graph(
      _.merge(
        {
          container,
          autoResize: false,
          interacting: {
            nodeMovable: false,
            edgeLabelMovable: false,
            arrowheadMovable: false,
          },
          // highlighting: {
          //   // 当链接桩可以被链接时，在链接桩外围渲染一个 2px 宽的红色矩形框
          //   magnetAvailable: {
          //     name: "stroke",
          //     args: {
          //       padding: 0,
          //       attrs: {
          //         "stroke-width": 2,
          //         stroke: "red",
          //       },
          //     },
          //   },
          // },
          connecting: {
            snap: { radius: 20 * scale },
            allowBlank: false,
            allowLoop: false,
            highlight: true,
          },
        },
        options
      )
    );
    // 注册事件
    Object.entries(events).forEach(([key, value]) => {
      graph.on(key, value);
    });
    return graph;
  };

  const registerPortLayout = (port: any) => {
    if (hasRegisterPort.includes(port.name)) {
      return;
    }
    Graph.registerPortLayout(port.name, port.fn);
    hasRegisterPort.push(port.name);
  };
  return {
    initGraph,
    registerPortLayout,
  };
};
