import { Chart } from "@antv/g2";
import { getScaleRate } from "@/utils/rem";

export default () => {
  const scale = getScaleRate();
  const createWheelCard = ({
    elmRef,
    list = [],
    createConfig,
  }: {
    elmRef: any;
    list: any[];
    createConfig: any;
  }) => {
    const chart = new Chart({
      container: elmRef,
      autoFit: false,
      height: 500 * scale,
      width: 500 * scale,
      ...createConfig,
    });

    const dataList = list.map((i) => ({ ...i, value: 1 }));
    chart.data(dataList);
    chart.tooltip(false);
    chart.coordinate("theta", {
      radius: 0.9,
      innerRadius: 0.6,
    });
    chart.legend(false);
    chart.animate(false);
    chart
      .interval()
      .adjust("stack")
      .position("value")
      .color("serial", (serial: any) => {
        const data = chart.getData();
        const item = data.find((i: any) => i.serial === serial) || {};
        const color = item.color;
        if (color === null || color === undefined) {
          return "#FFF";
        }
        return color;
      })
      .style({
        fields: ["choose"],
        callback: (choose: any) => {
          if (choose !== undefined && choose !== null) {
            return {
              lineWidth: 3 * scale,
              stroke: "#1a1a1a",
            };
          }
          return {
            lineWidth: 0.5 * scale,
            stroke: "#fff",
          };
        },
      })
      .label(false);

    return chart;
  };

  const createColorPiece = ({
    elmRef,
    data,
    createConfig,
  }: {
    elmRef: any;
    data: any;
    createConfig: any;
  }) => {
    const chart = new Chart({
      container: elmRef,
      autoFit: false,
      height: 150 * scale,
      width: 80 * scale,
      ...createConfig,
    });
    const info = { ...data, value: 1 };
    chart.data([info]);
    chart.animate(false);
    chart.coordinate("theta", {
      radius: 0.9,
      innerRadius: 0.6,
      startAngle: (1.5 - 1 / 12) * Math.PI,
      endAngle: Math.PI * (1.5 + 1 / 12),
    });
    chart.tooltip(false);
    chart.legend(false);
    chart
      .interval()
      .adjust("stack")
      .position("value")
      .color("color", (color: any) => {
        return color;
      })
      .label(false);
    return chart;
  };

  return {
    createWheelCard,
    createColorPiece,
  };
};
