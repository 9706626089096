import { ElLoading } from "element-plus";
import { getScaleRate } from "@/utils/rem";

/**
 * 生成随机码
 * @param len 随机码长度
 */
export const createRandomCode = (len = 6) => {
  const charset = `_0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`;
  const maxLen = charset.length;
  let ret = "";
  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * maxLen);
    ret += charset[randomIndex];
  }
  return ret;
};

export const domStrToImage = (domStr: string, scale = 2) => {
  return new Promise<string>((resolve, reject) => {
    const image = new Image();
    const svgBase64 = window.btoa(unescape(encodeURIComponent(domStr)));

    image.src = `data:image/svg+xml;base64,${svgBase64}`;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = image.width * scale;
      canvas.height = image.height * scale;
      context!.drawImage(image, 0, 0, canvas.width, canvas.height);
      // 如果画布的高度或宽度是0，那么 canvas.toDataURL() 会返回字符串“data:,”。
      const imageData = context?.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      ) || { data: [] };
      for (let i = 0; i < imageData.data.length; i += 4) {
        // 当该像素是透明的,则设置成白色
        if (imageData.data[i + 3] == 0) {
          imageData.data[i] = 255;
          imageData.data[i + 1] = 255;
          imageData.data[i + 2] = 255;
          imageData.data[i + 3] = 255;
        }
      }
      context?.putImageData(imageData as any, 0, 0);
      const dataUrl = canvas.toDataURL("image/png");
      if (dataUrl !== "data:,") {
        resolve(dataUrl);
      } else {
        reject(new Error("SAVE ERROR: THERE IS NO DOM ELEMENT"));
      }
    };

    image.onerror = () => reject(new Error("IMAGE LOAD ERROR！"));
  });
};

export function getUrlParam(name: string) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const result = window.location.search.substr(1).match(reg);
  if (!result) {
    return null;
  }

  return unescape(result[2]);
}

export const getBytesLength = (str: string) => {
  let count = str.length;
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      count++;
    }
  }
  return count;
};

// 去除图片的宽度并增加最大高度
export const addMaxHeightToImageStr = (str: string) => {
  const scale = getScaleRate();
  const exp = /(<img[\s\S]*?=[^>]*>*)/gi;
  const content = str.replace(exp, (match) => {
    let val = match.replace(/width="[0-9]*"/gi, " ");
    const ex = /style=('|")[^"]*?('|")/gi;
    if (ex.test(val)) {
      val = val.replace(ex, `style="max-height:${120 * scale}px;"`);
    } else {
      val = val.replace("<img", `<img style="max-height:${110 * scale}px;"`);
    }
    return val;
  });
  return content;
};

// 去除图片的高度并增加最大宽度
export const addMaxWidthToImageStr = (str: string) => {
  const scale = getScaleRate();
  const exp = /(<img[\s\S]*?=[^>]*>*)/gi;
  const content = str.replace(exp, (match) => {
    let val = match.replace(/width="[0-9]*"/gi, " ");
    const ex = /style=('|")[^"]*?('|")/gi;
    if (ex.test(val)) {
      val = val.replace(ex, `style="max-width:${730 * scale}px;"`);
    } else {
      val = val.replace("<img", `<img style="max-width:${730 * scale}px;"`);
    }
    // 去掉空style
    val = val.replace('style=""', " ");
    return val;
  });
  return content;
};

export const showLoading = (text: string, timeout = 30000, options = {}) => {
  let isLoading = false;
  let loading: any = null;
  const openLoading = () => {
    isLoading = true;
    loading = ElLoading.service(
      Object.assign(
        {},
        {
          lock: true,
          text,
          background: "rgba(0, 0, 0, 0.3)",
        },
        options
      )
    );
  };

  const closeLoading = () => {
    isLoading && loading && loading.close() && (isLoading = false);
  };

  if (isLoading) {
    closeLoading();
  } else {
    openLoading();
  }
  timeout && setTimeout(() => closeLoading(), timeout);
  return closeLoading;
};
