
import {
  ref,
  reactive,
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from "vue";
import _ from "lodash";
import DynamicContent from "./DynamicContent.vue";
import { unLock } from "@/utils/liner-lock";
import useCompileContent from "@/hooks/useCompileContent";

export default defineComponent({
  name: "DragQuestion",
  props: {
    question: Object,
    disabled: Boolean,
    showQuestionNum: {
      type: Boolean,
      default: true,
    },
    showQuestionScore: {
      type: Boolean,
      default: true,
    },
  },
  components: { DynamicContent },
  setup(props: any) {
    // 屏幕滑动翻页
    let startX = ref(0); // 触摸起始x
    let startY = ref(0); // 触摸起始y
    let endX = ref(0); // 触摸结束x
    let endY = ref(0); // 触摸结束y
    let canMove = ref(true); // 是否可以继续滑动
    let index = ref(0); // 控制滑动下标
    const isPreview = computed(() => props.disabled ?? false);
    const typeMap = {
      firstItem: { value: 0 },
      secondItem: { value: 1 },
    };
    const questionData = ref(props.question || {});
    const initAnswerData: any = ref(
      props.question.answer_params?.match_items || []
    );
    // 题干数据
    const firstItems = ref(props.question.question_params.first_items || []);
    // 选项数据
    const secondItems = ref(props.question.question_params.second_items || []);
    const sceneData = reactive({
      page: 1,
    });
    // 正确答案
    const correctAnswerList =
      props.question.question_params.correct_match_items?.map((i: any) => {
        i.first_item_content = firstItems.value.filter(
          (j: any) => j.id === i.first_item_id
        )[0].content;
        i.second_item_content = secondItems.value.filter(
          (j: any) => j.id === i.second_item_id
        )[0].content;
        return i;
      });

    // 激活项数据
    const activeItem: any = ref({});
    // 答案数据
    const answerData: any = computed(() =>
      firstItems.value
        .filter((i: any) => i.secondItemId)
        .map((i: any) => {
          return {
            first_item_id: i.id,
            second_item_id: i.secondItemId,
          };
        })
    );
    const { compileQuestionTitle } = useCompileContent();
    const questionTitle = ref<HTMLDivElement>();
    compileQuestionTitle(questionTitle, questionData.value.title || "");

    const handleScroll = (el: any, distance: number) => {
      const left = el.scrollLeft + distance;
      el.scrollTo({
        left,
        behavior: "smooth",
      });
    };
    // 左滚
    const goLeft = () => {
      const elem: any = document.getElementById("dragMiddle");
      handleScroll(elem, -250);
      // elem.scrollLeft += 250;
    };
    const goLeftCorrect = () => {
      const elem: any = document.getElementById("dragMiddleCorrect");
      handleScroll(elem, -250);
    };
    // 右滚
    const goRight = () => {
      const elem: any = document.getElementById("dragMiddle");
      handleScroll(elem, 250);
      // fjfwBox = document.querySelector("#Middle");
      // elem.scrollLeft -= 250;
      // document.getElementById("Middle").scrollTop -= 50;
    };
    const goRightCorrect = () => {
      const elem: any = document.getElementById("dragMiddleCorrect");
      handleScroll(elem, 250);
    };
    const moveStart = (e: any) => {
      startX.value = e.targetTouches[0].pageX;
      startY.value = e.targetTouches[0].pageY;
    };
    const onMove = (e: any) => {
      endX.value = e.targetTouches[0].pageX;
      endY.value = e.targetTouches[0].pageY;
      const dValueX = Math.abs(startX.value - endX.value);
      const dValueY = Math.abs(startY.value - endY.value);
      if (dValueX > dValueY) {
        // 水平滑动长度大于纵向滑动长度，那么选择水平滑动，阻止浏览器默认左右滑动事件
        e.preventDefault();
        if (dValueX > 40 && startX.value > endX.value && canMove.value) {
          // 向左划
          console.log("向左滑");
          canMove.value = false;
          index.value += 1;
          // handleNext();
        } else if (dValueX > 40 && canMove.value) {
          console.log("向右吗？");
          canMove.value = false;
          index.value -= 1;
          // handlePrevious();
        }
      } else {
        console.log("没有");
        // 恢复默认事件
        // window.event.returnValue = true;
      }
    };
    const moveEnd = (e: any) => {
      canMove.value = true;
      startX.value = endX.value = 0;
      startY.value = endY.value = 0;
    };
    // 移动端拖动项
    const draggingImgData: any = reactive({
      show: false,
      content: "",
    });

    const clearDraggingImg = () => {
      draggingImgData.preShow = false;
      draggingImgData.show = false;
      draggingImgData.style = {};
      draggingImgData.content = "";
    };

    // 数据变化操作
    const changeItem = (targetId: any, targetType: any, item: any = null) => {
      const sourceItem: any = item ?? activeItem.value;
      console.log("changeItem", sourceItem);
      if (
        sourceItem.type === typeMap.secondItem.value &&
        targetType === typeMap.firstItem.value
      ) {
        // 放入
        const oldFirstItem =
          firstItems.value.filter((i: any) => i.id === targetId)[0] || {};
        const nowSecondItem =
          secondItems.value.filter((i: any) => i.id === sourceItem.id)[0] || {};
        if (oldFirstItem?.secondItemId) {
          secondItems.value.push({
            id: oldFirstItem.secondItemId,
            content: oldFirstItem.secondItemContent,
          });
        }
        oldFirstItem.secondItemId = nowSecondItem.id;
        oldFirstItem.secondItemContent = nowSecondItem.content;
        secondItems.value = secondItems.value.filter(
          (i: any) => i.id !== sourceItem.id
        );
        return;
      }
      if (
        sourceItem.type === typeMap.firstItem.value &&
        targetType === typeMap.firstItem.value
      ) {
        // 木板内移动
        const target = _.cloneDeep(
          firstItems.value.filter((i: any) => i.id === targetId)[0] || {}
        );
        const source = _.cloneDeep(
          firstItems.value.filter((i: any) => i.id === sourceItem.id)[0] || {}
        );

        firstItems.value = firstItems.value.map((i: any) => {
          if (i.id === targetId) {
            i.secondItemId = source.secondItemId;
            i.secondItemContent = source.secondItemContent;
          } else if (i.id === sourceItem.id) {
            i.secondItemId = target.secondItemId;
            i.secondItemContent = target.secondItemContent;
          }
          return i;
        });
        return;
      }
      if (
        sourceItem.type === typeMap.firstItem.value &&
        targetType === typeMap.secondItem.value
      ) {
        // 放回
        const source = _.cloneDeep(
          firstItems.value.filter((i: any) => i.id === sourceItem.id)[0] || {}
        );
        if (!source.secondItemId) {
          return;
        }

        firstItems.value = firstItems.value.map((i: any) => {
          if (i.id === source.id) {
            delete i.secondItemContent;
            delete i.secondItemId;
          }
          return i;
        });
        secondItems.value.push({
          id: source.secondItemId,
          content: source.secondItemContent,
        });
      }
    };

    const handleDragStart = (element: any, e: any) => {
      const type = parseInt(element.getAttribute("type"));
      const secondItemId = element.getAttribute("secondItemId");
      if (type === typeMap.firstItem.value && !secondItemId) {
        e.preventDefault();
        return;
      }
      activeItem.value = {
        id: element.getAttribute("dataId"),
        type,
      };
    };

    const handleDrop = (el: any) => {
      const element = findDraggableEl(el);
      if (!element) {
        activeItem.value = {};
        return;
      }
      const dataId = element.getAttribute("dataId");
      const type = parseInt(element.getAttribute("type"));
      if (type >= 0) {
        changeItem(dataId, type);
      }
      activeItem.value = {};
    };

    const onDragStart = (e: any) => {
      handleDragStart(e.target, e);
    };
    const onDrop = (e: any) => {
      const element = e.target;
      handleDrop(element);
    };
    const findDraggableEl: any = (element: any) => {
      if (element.tagName === "BODY") {
        return null;
      }
      const draggable = element.getAttribute("draggable");
      const dropArea = element.getAttribute("dropArea");
      // console.log(1, draggable);
      if (!draggable && !dropArea) {
        if (element.parentNode) {
          return findDraggableEl(element.parentNode);
        } else {
          return null;
        }
      } else {
        if (draggable === "true" || dropArea === "true") {
          return element;
        }
        return null;
      }
    };
    const onTouchStart = (e: any) => {
      const element = findDraggableEl(e.target);
      if (!element) {
        return;
      }
      const type = parseInt(element.getAttribute("type"));
      const dataId = element.getAttribute("dataId");
      const secondItemId = element.getAttribute("secondItemId");
      let canDrag = false;
      if (
        dataId &&
        (type === typeMap.secondItem.value ||
          (type === typeMap.firstItem.value && secondItemId))
      ) {
        canDrag = true;
      }
      if (isPreview.value || !canDrag) {
        return;
      }
      draggingImgData.content = element.innerHTML;
      handleDragStart(element, e);
      const sourceTouch = e.changedTouches[0];
      draggingImgData.style = {
        top: `${sourceTouch.clientY}px`,
        left: `${sourceTouch.clientX}px`,
        width: `${element.offsetWidth}px`,
      };
      draggingImgData.preShow = true;
    };
    const onTouchEnd = (e: any) => {
      const sourceEl = e.changedTouches[0];
      if (!draggingImgData.show) {
        return;
      }
      clearDraggingImg();
      setTimeout(() => {
        const el = document.elementFromPoint(
          sourceEl.clientX,
          sourceEl.clientY
        );
        handleDrop(el);
      });
    };
    const onTouchCancel = (e: any) => {
      console.log("touchcancel", e.target);
      activeItem.value = {};
    };
    const onTouchMove = (e: any) => {
      if (!draggingImgData.preShow) {
        return;
      }
      const sourceTouch = e.changedTouches[0];
      if (!draggingImgData.show) {
        draggingImgData.show = true;
      }
      draggingImgData.style = {
        ...draggingImgData.style,
        top: `${sourceTouch.clientY}px`,
        left: `${sourceTouch.clientX}px`,
      };
      e.preventDefault();
    };
    const onDragOver = (event: any) => {
      event.preventDefault();
    };
    /**
     * 初始化数据
     */
    const initData = () => {
      initAnswerData.value.map((i: any) =>
        changeItem(i.first_item_id, typeMap.firstItem.value, {
          id: i.second_item_id,
          type: typeMap.secondItem.value,
        })
      );
      document.addEventListener("dragstart", onDragStart);
      document.addEventListener("drop", onDrop);
      document.addEventListener("dragover", onDragOver);
      document.addEventListener("touchstart", onTouchStart);
      document.addEventListener("touchend", onTouchEnd);
      document.addEventListener("touchcancel", onTouchCancel);
      // 禁止页面滑动
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", onTouchMove, { passive: false });
    };
    initData();

    const getAnswer = () => {
      const answerParams = {
        match_items: answerData.value,
      };
      if (!questionData.value.answer_params) {
        //* 没有回答过答案
        if (!answerData.value.length) {
          return { type: 0 };
        }
        return {
          type: 1,
          answer_params: answerParams,
        };
      } else {
        //* 回答过答案
        if (
          JSON.stringify(questionData.value.answer_params) ===
          JSON.stringify(answerParams)
        ) {
          //*  跟之前答过的一样
          return { type: 0 };
        }
        return {
          type: 1,
          answer_params: answerData.value.length > 0 ? answerParams : undefined,
        };
      }
    };

    onBeforeUnmount(() => {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchcancel", onTouchCancel);
      document.removeEventListener("dragstart", onDragStart);
      document.removeEventListener("dragover", onDragOver);
      document.removeEventListener("drop", onDrop);
    });

    onMounted(() => {
      unLock();
    });

    return {
      questionTitle,
      correctAnswerList,
      props,
      questionData,
      secondItems,
      firstItems,
      draggingImgData,
      isPreview,
      sceneData,
      typeMap,
      getAnswer,
      goLeft,
      goRight,
      moveStart,
      onMove,
      moveEnd,
      goRightCorrect,
      goLeftCorrect,
    };
  },
});
