export default () => {
  return {
    /**
     * 编创
     */
    creationInterface: {
      loadCreationQuestion: (
        iframeId: string,
        params: MusicScore.CreationParams
      ) => {
        window.__musicScoreInterface[iframeId].loadCreationQuestion(params);
      },
      getCreationResult: (
        iframeId: string,
        saveTime = true
      ): Promise<MusicScore.CreationResult> => {
        return window.__musicScoreInterface[iframeId].getCreationResult(
          saveTime
        );
      },
      reportSubmit(iframeId: string, params: MusicScore.CreationParams): void {
        window.__musicScoreInterface[iframeId].parentCreationDialogSubmit(
          params
        );
      },
      destroy: (iframeId: string): void => {
        window.__musicScoreInterface[iframeId] &&
          delete window.__musicScoreInterface[iframeId];
      },
      show: (iframeId: string) => {
        window.__musicScoreInterface[iframeId] &&
          window.__musicScoreInterface[iframeId].show();
      },
      hide: (iframeId: string) => {
        window.__musicScoreInterface[iframeId] &&
          window.__musicScoreInterface[iframeId].hide();
      },
    },
    singInterfaces: {
      loadSingQuestion: (iframeId: string, params: MusicScore.SingParams) => {
        window.__musicScoreInterface[iframeId].loadSingQuestion(params);
      },
      getSingResult: (iframeId: string): MusicScore.SingResult | undefined => {
        return window.__musicScoreInterface[iframeId].getSingResult();
      },
      reportSubmit(iframeId: string): void {
        window.__musicScoreInterface[iframeId].parentDialogSubmit();
      },
      destroy: (iframeId: string): void => {
        window.__musicScoreInterface[iframeId] &&
          delete window.__musicScoreInterface[iframeId];
      },
      show: (iframeId: string) => {
        window.__musicScoreInterface[iframeId] &&
          window.__musicScoreInterface[iframeId].show();
      },
      hide: (iframeId: string) => {
        window.__musicScoreInterface[iframeId] &&
          window.__musicScoreInterface[iframeId].hide();
      },
    },
    songInterfaces: {
      loadSongQuestion: (iframeId: string, params: MusicScore.SongParams) => {
        window.__musicScoreInterface[iframeId].loadSongQuestion(params);
      },
      getSongResult: (iframeId: string): MusicScore.SongResult | undefined => {
        return window.__musicScoreInterface[iframeId].getSongResult();
      },
      reportSubmit(iframeId: string): void {
        window.__musicScoreInterface[iframeId].parentDialogSubmit();
      },
      destroy: (iframeId: string): void => {
        window.__musicScoreInterface[iframeId] &&
          delete window.__musicScoreInterface[iframeId];
      },
      show: (iframeId: string) => {
        window.__musicScoreInterface[iframeId] &&
          window.__musicScoreInterface[iframeId].show();
      },
      hide: (iframeId: string) => {
        window.__musicScoreInterface[iframeId] &&
          window.__musicScoreInterface[iframeId].hide();
      },
    },
  };
};
